<template>
  <div id="login">
    <div class="login_back_img">
      <div class="login_center_div">
        <!-- M none -->
        <div class="login_center_left" v-if="is_PC == 'pc'">
          <img src="../assets/14878319.png" alt="" />
        </div>
        <!-- PC M共存 -->
        <div class="login_center_right">
          <p
            class="login_center_title"
            style="text-align: center; letter-spacing: 1px; font-weight: bold"
          >
            要旅行管理系统
          </p>
          <div class="login_input_view">
            <van-icon name="user-o" color="#e0e0e0" />
            <input
              class="login_user_input"
              placeholder-class="login_user_pla_input"
              type="text"
              v-model="admin"
              placeholder="请输入管理账号"
            />
          </div>
          <div class="login_input_view">
            <van-icon name="shield-o" color="#e0e0e0" />
            <input
              class="login_user_pla_input"
              type="password"
              v-model="password"
              placeholder="请输入管理密码"
            />
          </div>
          <!-- 记住密码 -->
          <div
            class="login_remember_password"
            :style="is_PC == 'pc' && 'height: 20px;overflow: hidden;'"
          >
            <p></p>
            <van-checkbox
              :icon-size="is_PC == 'pc' && '20px'"
              v-model="remember_password"
              >记住密码</van-checkbox
            >
          </div>
          <!-- 登录 -->
          <button @click="Sign" class="login_button">登录</button>
        </div>
      </div>
      <!-- logo+文字 -->
      <!-- <div class="login_icon_and_text">
        <img
          src="https://wx.yaolvxing.com/static/xcx/my_icont/basicprofile.jpg"
          alt=""
        />
        <p>要旅行</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      remember_password: false,
      admin: "",
      password: "",
      is_PC: this.Terminal_type,
    };
  },
  methods: {
    // 登录
    Sign() {
      if ((this.admin ?? "") == "" || (this.password ?? "") == "") {
        this.$message.error("请完善信息填写！");
        return false;
      }
      this.$https(
        "user/login",
        "account=" + this.admin + "&password=" + this.password,
        "post"
      ).then((res) => {
        // && (res.data.data.userinfo ?? '') !=''
        if (res.data.code == 1) {
          // token状态存储到vuex中
          this.$store.commit('add_Token',res.data.data.userinfo)
          // 头像昵称本地存储(会话)
          sessionStorage.setItem('nickname',res.data.data.userinfo.nickname)
          sessionStorage.setItem('avatar',res.data.data.userinfo.avatar)
          // 跳转
          this.$router.replace({
            name : 'WeChatApplet'
          })
        }
      });
    },
  },
};
</script>


<style scoped>
/* 穿透复选框 */
.login_remember_password >>> .van-checkbox .van-checkbox__label{
  margin-left: 0.5vw;
}
</style>
